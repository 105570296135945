<template>
<div class="signup">
  <div class="logo-container">
    <img src="/images/logo-2.svg" loading="lazy" alt="Amorino Logo" class="logo-image" @click="$router.push('/')"/>
  </div>
  <div class="section section-return">
    <div class="small-container">
      <div class="return-action" @click="$router.back()">
        ← REGRESAR
      </div>
    </div>
  </div>
<div class="section section-plan">
  <div class="small-container">
    <h1 class="benefits-header">Bienvenido</h1>
    <div class="info">
      Todo listo, ya puedes empezar a disfrutar los beneficios de ser parte de la familia Amorino Club.
    </div>
    <div class="action" @click="$router.push('/')">
      IR A INICIO
    </div>
  </div>
</div>
  <div>
    <div class="small-container">
      <div class="help">
        Si tienes alguna pregunta, escríbenos por WhatsApp a <a data-v-31534ce4="" href="https://wa.me/17085068383">+1.708.506.83.83</a>
      </div>
    </div>
  </div>
  <div class="marquee">
    <div class="track track-front">
      <div class="marquee-text">EXCLUSIVIDAD</div>
      <div class="marquee-text">·</div>
      <div class="marquee-text">INVITACIONES</div>
      <div class="marquee-text">·</div>
      <div class="marquee-text">CELEBRACIONES</div>
      <div class="marquee-text">·</div>
      <div class="marquee-text">PREFERENCIA</div>
      <div class="marquee-text">·</div>
      <div class="marquee-text">EXCLUSIVIDAD</div>
      <div class="marquee-text">·</div>
      <div class="marquee-text">INVITACIONES</div>
      <div class="marquee-text">·</div>
      <div class="marquee-text">CELEBRACIONES</div>
      <div class="marquee-text">·</div>
      <div class="marquee-text">PREFERENCIA</div>
      <div class="marquee-text">·</div>
      <div class="marquee-text">EXCLUSIVIDAD</div>
      <div class="marquee-text">·</div>
      <div class="marquee-text">INVITACIONES</div>
      <div class="marquee-text">·</div>
      <div class="marquee-text">CELEBRACIONES</div>
      <div class="marquee-text">·</div>
      <div class="marquee-text">PREFERENCIA</div>
      <div class="marquee-text">·</div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  data() {
    return {
      selectedPlan: null,
    };
  },
};
</script>

<style lang="scss" scoped>
.logo-container {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 24px 0;
}
.section-return {
  padding: 16px;
  font-size: 16px;
}
.help {
  width: 100%;
  text-align: center;
  padding: 12px 0;
}
.return-action {
  cursor: pointer;
}
.info {
  font-size: 18px;
  text-align: center;
}
.action {
  display: block;
  text-align: center;
  padding: 12px 12px;
  width: 40%;
  background-color:black;
  color: white;
  border: 0;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  border-radius: 100px;
  margin: 40px auto 0 auto;
}
</style>
